import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function blogCategoryHandler() {
  // Use toast
  const toast = useToast()

  const refBlogCategoryListTable = ref(null)

  const tableBlogCategoryColumns = [
    { key: 'title', label: 'Título', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]

  const perPage = ref(10)
  const totalBlogCategories = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const refetchData = () => {
    refBlogCategoryListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchBlogCategories = (ctx, callback) => {
    store
      .dispatch('app-blogs-categories/fetchBlogCategories', {
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results
        callback(data)
        totalBlogCategories.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching blog categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchBlogCategories,
    tableBlogCategoryColumns,
    perPage,
    currentPage,
    totalBlogCategories,
    perPageOptions,
    refBlogCategoryListTable,
    refetchData,
  }
}
