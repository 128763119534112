import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function postCategoryHandler(props, emit) {
  // ------------------------------------------------
  // postCategoryLocal
  // ------------------------------------------------
  const postCategoryLocal = ref(JSON.parse(JSON.stringify(props.category.value)))
  const resetPostCategoryLocal = () => {
    postCategoryLocal.value = JSON.parse(JSON.stringify(props.category.value))
  }
  watch(props.category, () => {
    resetPostCategoryLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const postCategoryData = JSON.parse(JSON.stringify(postCategoryLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.category.value.id) emit('update-category', postCategoryData.value)
    else emit('add-category', postCategoryData.value)

    // Close sidebar
    emit('update:is-post-category-handler-sidebar-active', false)
  }

  return {
    postCategoryLocal,
    resetPostCategoryLocal,

    // UI
    onSubmit,
  }
}
