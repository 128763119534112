import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBlogCategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/blog/categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/blog/category/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/blog/category/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
