<template>
  <div>
    <!-- Table Container Card -->
    <post-category-handler-sidebar
      v-model="isPostCategoryHandlerSidebarActive"
      :category="category"
      :clear-category-data="clearCategoryData"
      @add-category="addCategory"
      @update-category="updateCategory"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de post categorias</h3> <small> (Hay {{ totalBlogCategories }} categorias)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                class="mr-1"
                variant="info"
                @click="$router.push({ name: 'blogs'})"
              >
                <span class="text-nowrap">Listar posts</span>
              </b-button>
              <b-button
                variant="primary"
                @click="isPostCategoryHandlerSidebarActive = true"
              >
                <span class="text-nowrap">Agregar categoria</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBlogCategoryListTable"
        class="position-relative"
        :items="fetchBlogCategories"
        responsive
        :fields="tableBlogCategoryColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span v-can-permissions="'Editar categoria de los post'">
              <feather-icon
                :id="`category-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editCategory(data.item)"
              />
              <b-tooltip
                title="Editar"
                class="cursor-pointer"
                placement="right"
                :target="`category-row-${data.item.id}-send-icon`"
              />
            </span>

            <!--            <feather-icon-->
            <!--              :id="`invoice-row-${data.item.id}-preview-icon`"-->
            <!--              icon="TrashIcon"-->
            <!--              size="16"-->
            <!--              class="mx-1 cursor-pointer"-->
            <!--              @click="deletePost(data.item)"-->
            <!--            />-->
            <!--            <b-tooltip-->
            <!--              title="Eliminar"-->
            <!--              :target="`invoice-row-${data.item.id}-preview-icon`"-->
            <!--            />-->
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBlogCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination, BTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import blogHandler from '@/views/dramox/blog/blogHandler'
import blogStoreModule from '@/views/dramox/blog/blogStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import PostCategoryHandlerSidebar from '@/views/dramox/blog/category/PostCategoryHandlerSidebar'
import blogCategoryStoreModule from '@/views/dramox/blog/category/blogCategoryStoreModule'
import blogCategoryHandler from '@/views/dramox/blog/category/blogCategoryHandler'

export default {
  components: {
    PostCategoryHandlerSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BTooltip,
    BButton,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-blogs-categories'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, blogCategoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isPostCategoryHandlerSidebarActive = ref(false)
    const blankPostCategory = {
      id: null,
      title: '',
    }
    const category = ref(JSON.parse(JSON.stringify(blankPostCategory)))
    const clearCategoryData = () => {
      category.value = JSON.parse(JSON.stringify(blankPostCategory))
    }

    const editCategory = item => {
      category.value = item
      isPostCategoryHandlerSidebarActive.value = true
    }

    const {
      // fetchUsers,
      tableBlogCategoryColumns,

      perPage,
      currentPage,
      totalBlogCategories,

      perPageOptions,
      refBlogCategoryListTable,

      refetchData,
      fetchBlogCategories,
    } = blogCategoryHandler()

    const addCategory = val => {
      store.dispatch('app-blogs-categories/addCategory', { body: val })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          // fetchTasks()
          refetchData()
          showNotification({ title: 'Categoria agregado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }
    const removeCategory = () => {
      store.dispatch('app-blogs-categories/removeCategory', { id: category.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          // fetchTasks()
        })
    }
    const updateCategory = categoryData => {
      store.dispatch('app-blogs-categories/updateCategory', { id: category.value.id, body: categoryData })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          // fetchTasks()
          refetchData()
          showNotification({ title: 'Categoria actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    // const deletePost = item => {
    //   store.dispatch('app-blogs-categories/deleteBlog', { id: item.id })
    //     .then(() => {
    //       refetchData()
    //       showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
    //     })
    //     .catch(() => {
    //       showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
    //     })
    // }

    return {
      category,
      clearCategoryData,

      isPostCategoryHandlerSidebarActive,
      // fetchUsers,
      tableBlogCategoryColumns,
      perPage,
      currentPage,
      totalBlogCategories,
      perPageOptions,
      refBlogCategoryListTable,

      refetchData,
      fetchBlogCategories,

      editCategory,
      addCategory,
      removeCategory,
      updateCategory,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
